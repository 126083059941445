<script>
import liff from '@line/liff'
import axios from 'axios'
import Question from '@/components/flow/Question.vue'

export default {
  name: 'FlowItemSuggestions',
  components: {
    Question
  },
  data(){
    return {
      is_first_view: true,
      hasSkintypeId: true
    }
  },
  mounted(){
    const id_token = liff.getIDToken()
    const target_url = process.env.VUE_APP_FLOW_URL + 'item-suggestions'
    axios
      .post(target_url, {id_token: id_token, command: 'has_skintype_id'})
      .then((res) => {
        // メジャー情報
        this.hasSkintypeId = res.data.has_skintype_id
      })
      .catch((err) => {
        console.log("FlowItemSuggestions", err.code, err.message)
      })
  },
  methods: {
    startFlow(){
      if(this.hasSkintypeId){
        this.is_first_view = false;
      }
    }
  }
}
</script>

<template>
  <main>
    <section
      v-if="is_first_view"
      class="skincareItemCheck"
    >
      <div class="skincareItemCheck_container">
        <p>{{ $t('flowItemSuggestionsSubTitle') }}</p>
        <h1>{{ $t('askConcierges') }}</h1>
        <div class="skincareItemCheck_explanation">
          <p>{{ $t('flowItemSuggestionsDesc1') }}</p>
          <p>{{ $t('flowItemSuggestionsDesc2') }}</p>
        </div>
        <a
          disable
          :class="[hasSkintypeId? 'itemSuggestions_btn': 'itemSuggestions_btn_disabled']"
          @click="startFlow"
        >
          START
        </a>
      </div>
    </section>
    <section
      v-if="!hasSkintypeId"
      class="skincheck_diag_notyet_container"
    >
      <div class="skincheck_diag_notyet">
        <p>
          {{ $t('flowItemSuggestionsNoResultDesk1') }}<br><br>
          {{ $t('flowItemSuggestionsNoResultDesk2') }}<br>
          {{ $t('flowItemSuggestionsNoResultDesk3') }}
        </p>
        <div class="skincheckCheck_btn">
          <router-link
            to="/flow/skin-check"
          >
            {{ $t('startSkinCheckButton') }}
          </router-link>
        </div>
      </div>
    </section>
    <question
      v-if="!is_first_view"
      series="item-suggestions"
    />
  </main>
</template>

<style scoped>
h1 {
    font-size: 20px;
    font-weight: bold;
    color: #F29F8F;
    padding: 12px 0 28px 0;
    text-align: center;
}
a {
  text-decoration: none;
  color: #777;
}
.skincareItemCheck {
    background-color: #fff;
    font-size: 14px;
    padding: 0 40px;
}
.skincareItemCheck_container {
    padding: 45px 0;
}
.skincareItemCheck_container p:first-child{
  text-align: center;
}
.skincareItemCheck_explanation p:first-child {
    padding-bottom: 1em;
    text-align: left;
}
.skincareItemCheck_explanation {
    padding-bottom: 57px;
    text-align: left;
}
.itemSuggestions_btn {
    font-size: 24px;
    font-weight: bold;
    display: block;
    width: 90%;
    margin: 0 auto;
    border-radius: 100vh;
    text-align: center;
    padding: 15px 0;
    background-color: #F29F8F;
    color: #fff;
}
.itemSuggestions_btn_disabled {
    font-size: 24px;
    font-weight: bold;
    display: block;
    width: 90%;
    margin: 0 auto;
    border-radius: 100vh;
    text-align: center;
    padding: 15px 0;
    background-color: #777;
    color: #fff;
}
.skincheck_diag_notyet{
  line-height: 1.2;
}
.skincheck_diag_notyet p{
  padding: 20px 10px;
  font-size: 18px;
}
.skincheckCheck_btn{
  padding-bottom: 42px;
}
.skincheckCheck_btn a{
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #F29F8F;
  text-align: center;
  border-radius: 100vh;
  font-size: 20px;
}
.skincheck_diag_notyet_container{
  padding: 0 40px;
}
</style>

